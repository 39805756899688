import './Header.scss';
import React from 'react';

export class Header extends React.PureComponent {

	render() {
		return (
			<header>

				<h1>Volvo V60/S60 Wireless Chargepad Mount</h1>

			</header>
		);
	}
}

